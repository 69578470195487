<template>
    <vue-office-docx
            v-if="docx"
            :src="docx"
            style="height: 100vh;"
            @rendered="renderedHandler"
            @error="errorHandler"
    />
</template>

<script>
//引入VueOfficeDocx组件
import VueOfficeDocx from '@vue-office/docx'
//引入相关样式
import '@vue-office/docx/lib/index.css'

export default {
    components: {
        VueOfficeDocx
    },
    data() {
        return {
            docx: '' //设置文档网络地址，可以是相对地址
        }
    },
    mounted() {

        let url = location.search

        url = url.split("=")
        url[1] = url[1].replace('/flyhappypig', "")
        this.docx = url[1];
        console.log(this.docx, "this.docx")
    },
    methods: {
        renderedHandler() {

            console.log("渲染完成")
        },
        errorHandler(err) {
            console.log("渲染失败", err)
        }
    }
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
}
</style>
